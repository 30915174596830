import { translationsConstants } from '../constants';
const keys = JSON.parse(localStorage.getItem('keys'));
const initialKeys = keys ? keys : null;
const initialState = {
  keys: initialKeys
};
export const translations = (state = initialState, action) => {
  switch (action.type) {
    case translationsConstants.TRANSLATIONS_REQUEST:
      return {
        data: action.data
      };
    case translationsConstants.TRANSLATIONS_SUCCESS:
      return {
        data: action.data
      };
    case translationsConstants.TRANSLATIONS_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
};
