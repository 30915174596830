const env = process.env.REACT_APP_ENVIROMENT || "dev";
let msalConfig;
if (env === "prod") {
    msalConfig = {
        auth: {
            clientId: "cf3b0a11-d7d2-4f9d-a320-085f62ef2605",
            redirectUri: "https://live.ordema.net",
            postLogoutRedirectUri: "https://live.ordema.net"
        },
        cache: {
            cacheLocation: false,
            storeAuthStateInCookie: true
        }
    };
} else if (env === 'staging') {
    msalConfig = {
        auth: {
            clientId: "cf3b0a11-d7d2-4f9d-a320-085f62ef2605",
            redirectUri: "https://orderly.hyperether.com",
            postLogoutRedirectUri: "https://orderly.hyperether.com"
        },
        cache: {
            cacheLocation: false,
            storeAuthStateInCookie: true
        }
    };
} else {
    msalConfig = {
        auth: {
            clientId: "cf3b0a11-d7d2-4f9d-a320-085f62ef2605",
            redirectUri: "http://localhost:3001",
            postLogoutRedirectUri: "http://localhost:3001"
        },
        cache: {
            cacheLocation: false,
            storeAuthStateInCookie: true
        }
    };
};

export { msalConfig };