import { modalConstants } from '../constants';
const initialState = {
  show: false,
  content: {},
  name: '',
  size: '',
  data: {}
};

export const modal = (state = initialState, action) => {
  switch (action.type) {
    case modalConstants.SHOW_MODAL:
      return {
        content: action.content,
        show: action.show,
        name: action.name,
        size: action.size,
        data: action.data
      };
    case modalConstants.HIDE_MODAL:
      return {
        ...state,
        show: false
      };
    case modalConstants.UPDATE_MODAL:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
};
