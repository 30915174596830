import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './index.scss';
import { store } from './_store/store';
import logoIcon from './_assets/images/logo/ordema.png';
import { msalConfig } from './msalConfig';
import { Elements } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51JgnW7JQWr2ySbcdjIjGnYGa5AFOZ9wh0G7pPEQCRY7d8I8iCpNiJin1LRyu0QH4etvRGwlC6wxrTHZnaUXwXU7f00WBSouCSk');

const msalInstance = new PublicClientApplication(msalConfig);
const App = lazy(() => import('./App'));
const Loader = () => {
  return (<div className="pageLoader">
    <img src={logoIcon} alt="Ordema" /> <span>...</span>
  </div>);
};

ReactDOM.render(
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <ToastContainer className="toast" toastClassName="toast-wrapper" bodyClassName="toast-body" />
        <Suspense fallback={<Loader />}> <App pca={msalInstance} /></Suspense>
      </Provider>
    </Elements>,
  document.getElementById('root')
);

