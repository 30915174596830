import { dataConstants } from '../constants';
const initialState = {
  loading: false
};
export const data = (state = initialState, { actionType, type, name, mode, sent, result, id, error }) => {
  const newState = { ...state };
  if (!newState[name]) newState[name] = {};
  if (!newState[name][type]) newState[name][type] = { data: {}, list: [], page: { total: 0, rows: [] } };
  let typeData = newState[name][type];
  if (!typeData.list) typeData.list = [];
  if (!typeData.page) typeData.page = { total: 0, rows: [] };
  switch (actionType) {
    case dataConstants.DATA_REQUEST:
      typeData = { mode, sent, loading: true };
      break;
    case dataConstants.DATA_SUCCESS:
      if (mode === 'get' || mode === 'new' || mode === 'update') {
        if (result) {
          typeData[id || result._id] = result;
        }
      } else if (mode === 'all') {
        typeData.list = result;
      } else if (mode === 'page') {
        typeData.page = result;
        typeData.page.rows.map(item => {
          Object.assign(item, { selected: false });
        });
      }
      typeData.loading = false;
      newState[name][type] = { ...typeData };
      break;
    default:
      return newState;
  }
  return newState;
};
