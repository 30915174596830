
export const utilsConstants = {
  DUPLICATED_DATA: 'DUPLICATED_DATA',
  EMPTY_VALIDATION_FORM: 'EMPTY_VALIDATION_FORM',
  UNDO_DELETE: 'UNDO_DELETE',
  UNDO_REFRESH: 'UNDO_REFRESH',
  ATTACHED_FILES: 'ATTACHED_FILES',
  SHOW_NOTIF_ICON: 'SHOW_NOTIF_ICON',
  FULL_LOADER: 'FULL_PAGE_LOADER',
  APPROVAL_EXTERNAL: 'APPROVAL_EXTERNAL',
  APPROVAL_INTERNAL: 'APPROVAL_INTERNAL',
  SIGNATURE_FILES: 'SIGNATURE_FILES',
  UPLOADED_FILE: 'UPLOADED_FILE',
  EDIT_APPROVAL: 'EDIT_APPROVAL',
  DOWNLOAD_DATA: 'DOWNLOAD_DATA',
  ACTIVE_FORM: 'ACTIVE_FORM',
  VERSION_SENT_BY_NAME: 'VERSION_SENT_BY_NAME',
  BINDED_FILES: 'AGREEMENT_BINDED_FILES',
  CARD_SIZES: 'CARD_SIZES',
  CHART_DATES: 'CHART_DATES',
  CHART_FILTER: 'CHART_FILTER',
  ROLES: 'ROLES',
  ROLE: 'ROLE',
  USER_ROLE: 'USER_ROLE',
  USER_AGREEMENT_ACCESS: 'USER_AGREEMENT_ACCESS'
};
