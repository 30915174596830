import { filterConstants } from '../constants';

const initialState = {};
export const filter = (state = initialState, action) => {
  switch (action.type) {
    case filterConstants.SELECT_FILTER:
      return {
        state: action
      };
    case filterConstants.DELETE_FILTER:
      return {
        state: {}
      };
    default:
      return state;
  }
};
