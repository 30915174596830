import { eventConstants } from '../constants';

const initialState = {
};

export const event = (state = initialState, payload) => {
  const { type, event } = payload;
  const newState = { ...state };
  switch (type) {
    case eventConstants.RECEIVED_EVENT:
      newState.data = event;
      break;
    default:
      return newState;
  }
  return newState;
};
