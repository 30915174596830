import { combineReducers } from 'redux';
import { authConstants } from '../constants/auth.constants';
import { auth } from './auth.reducer';
import { modal } from './modal.reducer';
import { data } from './data.reducer';
import { forms } from './forms.reducer';
import { translations } from './translation.reducer';
import { filter } from './filter.reducer';
import { utils } from './utils.reducer';
import { event } from './events.reducer';
const appReducer = combineReducers({
  auth,
  data,
  modal,
  forms,
  translations,
  utils,
  filter,
  event
});

const rootReducer = (state, action) => {
  // reset store to initial state
  if (action.type === authConstants.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
