import { utilsConstants } from '../constants';
const initial = {
  duplicated: {},
  emptyValidationForm: false,
  undoDelete: {},
  undoRefresh: {},
  attachedFiles: {
    submit: false,
    files: []
  },
  loader: false,
  internalApproval: [],
  externalApproval: [],
  signitureFiles: [],
  uploadedFile: {},
  editApprovalShow: true,
  downloadData: [],
  activeForms: [],
  versionSentBy: '',
  agreementBindedFiles: [],
  cardSize: 'Min',
  chartDate: {},
  chartFilter: [],
  roles: {},
  role: {},
  userRole: {},
  userAgreementAccess: {}
};

export const utils = (state = initial, action) => {
  switch (action.type) {
    case utilsConstants.DUPLICATED_DATA:
      return {
        ...state,
        duplicated: action.data
      };
    case utilsConstants.EMPTY_VALIDATION_FORM:
      return {
        ...state,
        emptyValidationForm: action.data
      };
    case utilsConstants.UNDO_DELETE:
      return {
        ...state,
        undoDelete: action.data
      };
    case utilsConstants.UNDO_REFRESH:
      return {
        ...state,
        undoRefresh: action.data
      };
    case utilsConstants.ATTACHED_FILES:
      return {
        ...state,
        attachedFiles: action.data
      };
    case utilsConstants.SHOW_NOTIF_ICON:
      return {
        ...state,
        showNotifIcon: action.data
      };
    case utilsConstants.FULL_LOADER:
      return {
        ...state,
        loader: action.data
      };
    case utilsConstants.APPROVAL_INTERNAL:
      return {
        ...state,
        internalApproval: action.data
      };
    case utilsConstants.APPROVAL_EXTERNAL:
      return {
        ...state,
        externalApproval: action.data
      };
    case utilsConstants.SIGNATURE_FILES:
      return {
        ...state,
        signitureFiles: action.data
      };
    case utilsConstants.UPLOADED_FILE:
      return {
        ...state,
        uploadedFile: action.data
      };
    case utilsConstants.EDIT_APPROVAL:
      return {
        ...state,
        editApprovalShow: action.data
      };
    case utilsConstants.DOWNLOAD_DATA:
      return {
        ...state,
        downloadData: action.data
      };
    case utilsConstants.ACTIVE_FORM:
      return {
        ...state,
        activeForms: action.data
      };
    case utilsConstants.VERSION_SENT_BY_NAME:
      return {
        ...state,
        versionSentBy: action.data
      };
    case utilsConstants.BINDED_FILES:
      return {
        ...state,
        agreementBindedFiles: action.data
      };
    case utilsConstants.CARD_SIZES:
      return {
        ...state,
        cardSize: action.data
      };
    case utilsConstants.CHART_DATES:
      return {
        ...state,
        chartDate: action.data
      };
    case utilsConstants.CHART_FILTER:
      return {
        ...state,
        chartFilter: action.data
      };
    case utilsConstants.ROLES:
      return {
        ...state,
        roles: action.data
      };
    case utilsConstants.ROLE:
      return {
        ...state,
        role: action.data
      };
    case utilsConstants.USER_ROLE:
      return {
        ...state,
        userRole: action.data
      };
    case utilsConstants.USER_AGREEMENT_ACCESS:
      return {
        ...state,
        userAgreementAccess: action.data
      };
    default:
      return state;
  }
};
