export const authConstants = {
  OTHER_REQUEST: 'USERS_OTHER_REQUEST',
  OTHER_SUCCESS: 'USERS_OTHER_SUCCESS',
  OTHER_FAILURE: 'USERS_OTHER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  GOOGLE_LOGIN_REQUEST: 'GOOGLE_LOGIN_REQUEST',
  GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
  GOOGLE_LOGIN_FAILURE: 'GOOGLE_LOGIN_FAILURE',

  APPLE_LOGIN_REQUEST: 'APPLE_LOGIN_REQUEST',
  APPLE_LOGIN_SUCCESS: 'APPLE_LOGIN_SUCCESS',
  APPLE_LOGIN_FAILURE: 'APPLE_LOGIN_FAILURE',

  MICROSOFT_LOGIN_REQUEST: 'MICROSOFT_LOGIN_REQUEST',
  MICROSOFT_LOGIN_SUCCESS: 'MICROSOFT_LOGIN_SUCCESS',
  MICROSOFT_LOGIN_FAILURE: 'MICROSOFT_LOGIN_FAILURE',

  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',


  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  RESET_LINK_REQUEST: 'RESET_LINK_REQUEST',
  RESET_LINK_SUCCESS: 'RESET_LINK_SUCCESS',
  RESET_LINK_FAILURE: 'RESET_LINK_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  RESET_FORGOT_PASSWORD: 'RESET_FORGOT_PASSWORD',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  INVITE_TO_EDITOR_REQUEST: 'INVITE_TO_EDITOR_REQUEST',
  INVITE_TO_EDITOR_SUCCESS: 'INVITE_TO_EDITOR_SUCCESS',
  INVITE_TO_EDITOR_FAILURE: 'INVITE_TO_EDITOR_FAILURE',

  INVITE_TO_SIGNATURE_REQUEST: 'INVITE_TO_SIGNATURE_REQUEST',
  INVITE_TO_SIGNATURE_SUCCESS: 'INVITE_TO_SIGNATURE_SUCCESS',
  INVITE_TO_SIGNATURE_FAILURE: 'INVITE_TO_SIGNATURE_FAILURE',

  INVITE_REQUEST: 'INVITE_REQUEST',
  INVITE_SUCCESS: 'INVITE_SUCCESS',
  INVITE_FAILURE: 'INVITE_FAILURE',

  UPDATE_STORE_USER: 'UPDATE_STORE_USER'
};
