import { authConstants } from '../constants';
const user = JSON.parse(localStorage.getItem('user'));
const showNotifIcon = JSON.parse(localStorage.getItem('unreadNotif'));
const initialShowNotifIcon = showNotifIcon === 'true' ? true : false;
const initialUser = user ? user : null;
const initialState = {
  user: initialUser,
  forgotPassword: false,
  showConfirm: false,
  loading: false,
  showNotifIcon: initialShowNotifIcon
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        user: action.user
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        user: action.user
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state
      };
    case authConstants.REGISTER_REQUEST:
      return {
        user: action.user
      };
    case authConstants.REGISTER_SUCCESS:
      return {
        user: action.user
      };
    case authConstants.REGISTER_FAILURE:
      return {
        ...state
      };
    case authConstants.GOOGLE_LOGIN_REQUEST:
      return {
        user: action.user
      };
    case authConstants.GOOGLE_LOGIN_SUCCESS:
      return {
        user: action.user
      };
    case authConstants.GOOGLE_LOGIN_FAILURE:
      return {
        ...state
      };
    case authConstants.APPLE_LOGIN_REQUEST:
      return {
        user: action.user
      };
    case authConstants.APPLE_LOGIN_SUCCESS:
      return {
        user: action.user
      };
    case authConstants.APPLE_LOGIN_FAILURE:
      return {
        ...state
      };
    case authConstants.MICROSOFT_LOGIN_REQUEST:
      return {
        user: action.user
      };
    case authConstants.MICROSOFT_LOGIN_SUCCESS:
      return {
        user: action.user
      };
    case authConstants.MICROSOFT_LOGIN_FAILURE:
      return {
        ...state
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        user: null
      };

    case authConstants.UPDATE_USER_REQUEST:
      return {
        ...state
      };

    case authConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.user
      };

    case authConstants.UPDATE_USER_FAILURE:
      return {
        ...state
      };

    case authConstants.RESET_LINK_REQUEST:
      return {
        ...state,
        forgotPassword: true
      };
    case authConstants.RESET_LINK_SUCCESS:
      return {
        ...state,
        forgotPassword: false,
        showConfirm: true
      };
    case authConstants.RESET_LINK_FAILURE:
      return {
        ...state,
        forgotPassword: false
      };
    case authConstants.RESET_FORGOT_PASSWORD:
      return {
        ...state,
        showConfirm: false
      };
    case authConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state
      };

    case authConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.user
      };

    case authConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state
      };
      case authConstants.INVITE_TO_EDITOR_REQUEST:
        return {
          ...state
        };
  
      case authConstants.INVITE_TO_EDITOR_SUCCESS:
        return {
          ...state,
          user: action.user
        };
  
      case authConstants.INVITE_TO_EDITOR_FAILURE:
        return {
          ...state
        };
    case authConstants.UPDATE_STORE_USER:
      return {
        ...state,
        user: action.user
      };

    default:
      return state;
  }
};
