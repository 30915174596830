import { formsConstants } from '../constants';

export const forms = (state = {}, action) => {
  switch (action.type) {
    case formsConstants.FORMS_REQUEST:
      return {
        ...state
      };
    case formsConstants.FORMS_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case formsConstants.FORMS_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
